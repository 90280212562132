export class AffiliateControl extends HTMLElement {
  connectedCallback() {
    this.config = {
      baseUrl: window.location.origin,
      cookieCreationPath: "/api/affiliate/click",
      purchasePredictionPath: "/api/affiliate/checkout/",
      cookieName: "Affiliate",
      confirmationPageIdentifier: "/shop/home/bestellen",
      oldPrivisionCookieName: "Provision",
      oldProvIdCookieName: "ProvID",
      provitionParameterKey: "ProvID",
      loginPath: "/shop/home/login/login"
    };

    window.addEventListener('DOMContentLoaded', (event) => {
      this.runAction();
    });
  }

  async runAction() {
    if (this.shouldCookieCreationBeTriggered()) {
      await this.triggerCookieCreation();
    }

    if (this.isCheckoutSeite()) {
      return await this.sendCheckoutPrediction();
    }

    if (this.isDankeSeite()) {
      return this.writeDatalayer();
    }
  }

  getMetaAttribut() {
    const meta = window.document.querySelector("meta[name='afs-affiliate-site-key']");
    if (meta) {
      return meta.getAttribute('content');
    }
    return "";
  }

  isCheckoutSeite(){
    const kaufenButton = window.document.querySelector('button[name=bestellen]');
    return kaufenButton || this.getMetaAttribut() === 'checkout';
  }

  isDankeSeite(){
    return this.isShopBestellbestaetigungSeite() || this.getMetaAttribut() === 'bestellbestaetigung';
  }

  isShopBestellbestaetigungSeite(){
    const kaufenButton = window.document.querySelector('button[name=bestellen]');
    return !kaufenButton && window.location.pathname.includes(this.config.confirmationPageIdentifier);
  }

  async sendCheckoutPrediction() {
    try {
      await fetch(this.config.baseUrl + this.config.purchasePredictionPath);
    } catch (error) {
      // ignore errors
    }
  }

  // AFS-5912: Datalayer auf der Danke-Seite befüllen
  writeDatalayer() {
    const affiliateCookie = this.getCookieByName(this.config.cookieName);

    if (affiliateCookie) {
      window.thaliaShell.datalayer.purchaseObject.register(
        () => {
          const affiliateCookieJson = JSON.parse(window.atob(affiliateCookie));

          const data = {
            provisionId: affiliateCookieJson.provId,
            publisherId: affiliateCookieJson.publisherId,
            subId: affiliateCookieJson.subId
          };

          return Promise.resolve(data);
        }
      );
    }
  }

  shouldCookieCreationBeTriggered() {
    const isNewOnThisDomain = !window.document.referrer.startsWith(window.location.origin);
    const isEntryFromLogin = window.document.referrer.includes(this.config.loginPath);
    const hasURLParameters = !!window.location.search;
    const hasProvisionParameters = hasURLParameters && window.location.search.includes(this.config.provitionParameterKey);
    const hasProvIdCookie = !!this.getCookieByName(this.config.oldProvIdCookieName);
    const hasProvisionCookie = !!this.getCookieByName(this.config.oldPrivisionCookieName);
    const hasRefferer = !!window.document.referrer;
    return (isNewOnThisDomain || (isEntryFromLogin && hasProvisionParameters)) // TRUE wenn der Refferer NICHT mit dem origin der Seite beginnt oder Einsprung kommt von Login.
      && (hasURLParameters // TRUE wenn URL-QueryParameter vorhanden sind.
        || hasProvIdCookie // TRUE wenn ein alter ProvID-Cookie vorhanden ist.
        || hasProvisionCookie // TRUE wenn ein alter Provision-Cookie vorhanden ist.
        || hasRefferer); // TRUE wenn ein Refferer gesetzt ist.
  }

  async triggerCookieCreation() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const originalParameterResult = this.convertUrlSearchParamsIntoJSONObject(urlParams);
      const payload = {
        originalReferrer: window.document.referrer,
        originalParameter: originalParameterResult
      };
      await fetch(this.config.baseUrl + this.config.cookieCreationPath, {
        method: "post",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
      });
    } catch (error) {
      // ignore errors
    }
  }

  convertUrlSearchParamsIntoJSONObject(searchParams) {
    const result = {};
    searchParams.forEach(
      (paramValue, paramKey) => {
        result[paramKey] = paramValue;
      }
    );
    return result;
  }

  getCookieByName(name) {
    const match = document.cookie.match(new RegExp(`${name}=([^;]+)`));
    if (match) {
      return match[1];
    }
    return "";
  }
}

// Polyfill für startsWith-Funktion, da diese vom IE nicht unterstützt wird
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

if (!customElements.get("affiliate-header-script")) {
  customElements.define("affiliate-header-script", AffiliateControl);
}
document.querySelector('body').insertAdjacentHTML('beforeend', `<affiliate-header-script></affiliate-header-script>`);
